<template>
  <div>
    <el-collapse v-model="filter" @change="handleChange">
      <el-collapse-item title="Фильтр" name="p1">
        <el-row class="m-0 filter">
          <el-col :span="8" class="ml-2 filter--container">
            <span class="mr-2" style="min-width:8rem;">Наименование:</span>
            <el-input v-model="filterName"></el-input>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <div>
      <el-table v-loading="loading"
                :data="gridItemsFiltered"
                :default-sort="{prop: 'name', order: 'ascending'}"
                style="width: 95%; margin: 0 2.5%;"
                class="report--table">
        <el-table-column label="" prop="name">
          <template slot-scope="scope">
            <div class="report--button" @click="handleView(scope.row)">
              <el-button type="text" icon="el-icon-document"
                         @click="handleView(scope.row)"></el-button>
              {{scope.row.name}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import reportsApi from '@/api/reports';
  import tableResize from '@/mixins/tableResize.js';
  import UserSettingsService from '@/service/userSettingsService';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Reports',
    mixins: [tableResize],
    data() {
      return {
        loading: false,
        gridItems: [],
        filterName: null,
      };
    },
    computed: {
      gridItemsFiltered() {
        if (!this.filterName) {
          return this.gridItems;
        } else {
          return this.gridItems.filter((x) => x.name.toLowerCase().indexOf(this.filterName.toLowerCase()) >= 0);
        }
      },
      ...mapGetters('identity', ['user']),
    },
    created() {
      if (UserSettingsService.has('reports_filter_open', this.user.id)) {
        this.filter = ['p1'];
      }
    },
    async mounted() {
      this.loading = true;
      await this.getData();
      this.loading = false;
      setTimeout(() => {
        this.tableHeight = this.getTableHeight();
        this.customColumnResize();
      }, 100);
    },
    methods: {
      handleChange(val) {
        if (val.find((el) => el === 'p1')) {
          UserSettingsService.set('reports_filter_open', true, this.user.id);
        } else {
          UserSettingsService.delete('reports_filter_open', this.user.id);
        }
      },
      // eslint-disable-next-line no-unused-vars
      async getData() {
        const response = await reportsApi.getManyPublished();
        this.gridItems = response.data.items;
      },
      handleView(report) {
        this.$router.push({ name: 'ReportView', params: { reportId: report.id } });
      },
    },
  };
</script>

<style scoped lang="scss">
  td {
    border-bottom: none;
  }
</style>
