import axios from 'axios';

export default {
  getManyPublished() {
    return axios.get('/api/Reports/GetManyPublished');
  },

  getSinglePublished(reportId) {
    return axios.get(`/api/Reports/GetSinglePublished/${reportId}`);
  },

  downloadFile(reportId, params) {
    return axios.post('/api/Reports/DownloadFile', { reportId, params });
  },

  downloadPreviewFile(metaDto, params) {
    return axios.post('/api/Reports/DownloadPreviewFile', { dto: metaDto, params });
  },

  downloadEmptyTemplateFile(metaDto) {
    return axios.post('/api/Reports/DownloadEmptyTemplateFile', { dto: metaDto });
  },

  getSql(metaDto) {
    return axios.post('/api/Reports/GetSql', { dto: metaDto });
  },

  getDBSchema() {
    return axios.get('/api/Reports/GetDBSchema');
  },

  getSingle(reportId) {
    return axios.get(`/api/Reports/GetSingle/${reportId}`);
  },

  save(reportDto) {
    return axios.post('/api/Reports/Save', { dto: reportDto });
  },

  getMany() {
    return axios.get('/api/Reports/GetMany');
  },

  delete(reportId) {
    return axios.delete(`/api/Reports/Delete/${reportId}`);
  },

  copy(reportId) {
    return axios.post('/api/Reports/Copy', { id: reportId });
  },

  saveDBMetaFile(bytes) {
    return axios.post('/api/Reports/SaveDbMetaFile', { content: bytes });
  },

  downloadEmptyDbMetaFile() {
    return axios.get('/api/Reports/DownloadEmptyDbMetaFile');
  },

  downloadDbMetaFile() {
    return axios.get('/api/Reports/DownloadDbMetaFile');
  },

  getCatalogs(reportId) {
    return axios.post('/api/Reports/GetCatalogs', { reportId });
  },

  getPreviewCatalogs(metaDto) {
    return axios.post('/api/Reports/GetPreviewCatalogs', { dto: metaDto });
  },
  updateReportAccess(reportId, allowedOrganisations) {
    return axios.post('/api/Reports/UpdateReportAccess', { reportId, allowedOrganisations });
  },
  createPrimaryEquipmentsReport(params) {
    return axios.post('/api/Reports/CreatePrimaryEquipmentsReport', params);
  }
};
